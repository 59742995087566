<template>
	<div class="mb-12">
	<container class="container mb-12" :headline="headline">
		<template v-slot:body>
			<div class="flex flex-col md:flex-row flex-1 justify-between gap-5 mt-12">
				<contact-item-wrapper
					:title="emailTitle"
					:value="emailValue"
				/>
				<contact-item-wrapper class="h-full"
					:title="postTitle"
					:company-name="postCompanyName"
					:street="street"
					:additional-info="additionalInfo"
					:postal-city="postalCity"
				/>
			</div>
		</template>
	</container>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import Container from "@/components/Container.vue";
import contactItemWrapper from "@/components/shared/ContactItemWrapper.vue";
export default defineComponent({
	name: "Contact",
	components: {
		Container,
		contactItemWrapper
	},
	data() {
		return {
			headline: "Kontakt",
			emailTitle: "E-mail",
			emailValue: "info@btntechnologies.com",
			postTitle: "Post",
			postCompanyName: "BTN Technologies GmbH ",
			additionalInfo: "Scanbox #11061",
			street: "Ehrenbergstr. 16a",
			postalCity: "10245 Berlin"
		}
	}
});
</script>

<style scoped>
.container {
	margin-top: 123px;
}
</style>