<template>
	<div
		class="footer-view mt-12 py-10 flex flex-col md:flex-row items-center justify-between text-base relative bottom-0 -mx-5 pr-5 md:-mx-18 md:pr-18">
		<div class=""/>
		<div class="flex flex-row mb-6.5 md:mb-0">
			<img class="mr-1.5" src="/icons/logo-flag-small.svg" alt="logo-small">
			<span>
				<span class="font-bold">BTN </span>
				<span>Technologies GmbH</span>
			</span>
		</div>
		<div class="flex flex-row gap-6">
			<span
				class="cursor-pointer hover:underline"
				@click.prevent="navigateToImprint">Impressum</span>
			<span
				class="cursor-pointer hover:underline"
				@click.prevent="navigateToDataProtection">Datenschutz</span>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "FooterView",
	methods: {
		navigateToImprint() {
			this.$router.push({ name: "imprint" });
		},
		navigateToDataProtection() {
			this.$router.push({ name: "dataProtection" });
		}
	}
});
</script>

<style scoped>
.footer-view {
	background-color: #F7F7F7;
}
</style>