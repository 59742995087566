<template>
	<div class="banner h-72 w-full mb-11" :style="backgroundBanner">
		<div class="banner-text flex flex-col relative text-white w-fit">
			<span class="font-extrabold text-3xl mb-2">Health Data Hub</span>
			<p class="flex flex-col text-base leading-6">
				<span>Erfassung und Konsolidierung</span>
				<span>statistischer Gesundheitsdaten</span>
			</p>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
	name: "Banner",
	computed: {
		backgroundBanner () {
			return `background-image: url("/img/background-image.jpg");`;
		}
	}
})
</script>

<style scoped>
.banner {
	height: 306px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-origin: content-box;
}
.banner-text {
	left: 30px;
	top: 30px;
}
</style>