<template>
	<container class="container mb-10" :headline="headline">
		<template v-slot:body>
			<p class="imprint-text text-base mt-20">
				<span class="font-bold">Herausgeber</span><br/><br/>
				<span>
					<span>BTN Technologies GmbH</span> <br/>
					<span>Rosenthaler Str. 72a</span><br/>
					<span>10119 Berlin</span>

					<br/><br/><br/>

					<span>Registergericht: Amtsgericht Berlin (Charlottenburg)</span> <br/>
					<span>Registernummer: HRB 248380B</span><br/>
					<span>Sitz der Gesellschaft: Berlin</span><br/>
					<span>Geschäftsführer: Thorben Hasberg</span>
				</span>

				<br/><br/><br/>

				<span class="font-bold">Kontakt</span><br/>
				<span>imprint@btntechnologies.com</span>

				<br/><br/><br/>

				<span class="font-bold">Serverstandort</span><br/>
				<span>Bundesrepublik Deutschland</span>

				<br/><br/><br/>

				<span class="font-bold">Umsatzsteuer-Identifikationsnummer</span><br/>
				<span>beantragt</span>

				<br/><br/><br/>

				<span class="font-bold">EU-Streitschlichtung</span><br/>
				<span>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:https://ec.europa.eu/consumers/odr.
					Unsere E-Mail-Adresse finden Sie oben im Impressum.</span>
			</p>
		</template>
	</container>
</template>

<script>
import Container from "@/components/Container.vue";

export default {
	name: "Imprint",
	components: {
		Container
	},
	data() {
		return {
			headline: "Impressum"
		}
	},
	mounted () {
		window.scrollTo(0,0);
	}
}
</script>

<style scoped>
.container {
	margin-top: 123px;
}
.imprint-text {
	//line-height: 12px;
}
</style>