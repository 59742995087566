<template>
	<div
		class="contact-item text-white flex flex-1 flex-col gap-5 md:flex-row px-6 md:px-10 py-12 md:items-center md:justify-between">
		<span class="text-xl font-bold"> {{ title }} </span>
		<span v-if="value" class="value underline underline-offset-4"> {{ value }} </span>
		<div v-if="companyName" class="flex flex-col">
			<span class="value underline underline-offset-4"> {{ companyName }} </span>
			<span class="value underline underline-offset-4"> {{ street }} </span>
			<span class="value underline underline-offset-4"> {{ additionalInfo }} </span>
			<span class="value underline underline-offset-4"> {{ postalCity }} </span>

		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "ContactItemWrapper",
	props: {
		title: String,
		value: String,
		companyName: String,
		street: String,
		additionalInfo: String,
		postalCity: String
	}
});
</script>

<style scoped>
.contact-item {
	background-color: #3E3E3E;
}
.value {
	max-width: 230px;
}
</style>