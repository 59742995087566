<template>
	<div class="header-view flex flex-row justify-between items-center">
		<div
			class="header h-16 w-32 cursor-pointer"
			:style="backgroundLogoBtn"
			@click.prevent="navigateToHomePage"
		/>
		<span
			class="cursor-pointer hover:underline"
			@click.prevent="navigateToContact">Kontakt</span>
	</div>
</template>

<script>
export default {
	name: "HeaderView",
	computed: {
		backgroundLogoBtn () {
			return `background-image: url("/icons/btn-technologies-logo.svg");`;
		}
	},
	methods: {
		navigateToHomePage() {
			this.$router.push({ name: "home" });
		},
		navigateToContact() {
			this.$router.push({ name: "contact" });
		}
	}
}
</script>

<style scoped>
.header-view {
	margin-bottom: 26px;
}
.header {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-origin: content-box;
}
</style>