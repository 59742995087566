<template>
	<div
		class="extern-link flex flex-col w-full md:flex-row justify-between items-center px-6 md:px-10 py-7 md:py-9 gap-9 md:gap-0">
		<div class="flex flex-row gap-3.5">
			<img class="h-12 w-9" :src="icon" alt="icon">
			<div class="flex flex-col">
				<span class="font-bold text-xl"> {{ title }} </span>
				<span class="subTitle text-base"> {{ subTitle }} </span>
			</div>
		</div>
		<a class="underline underline-offset-4 flex flex-row items-center self-end md:self-auto" :href="link">
			<span class="mr-1.5">Website öffnen</span>
			<img class="link-icon" src="/icons/extern-link-icon.svg" alt="link">
		</a>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "ExternLinkWrapper",
	props: {
		icon: {
			type: String,
			require: true
		},
		title: {
			type: String,
			require: true
		},
		subTitle: {
			type: String,
			require: true
		},
		link: {
			type: String,
			require: true
		},
	}
});
</script>

<style scoped>
.extern-link {
	background-color: #F5F5DE;
	margin-bottom: 30px;
}
.link-icon {
	height: 10px;
}

@media only screen and (min-width: 42em) and (max-width: 64em) {
	.subTitle {
		max-width: 303px;
	}
}
</style>