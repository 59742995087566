<template>
	<div class="flex flex-col">
		<h2 class="font-extrabold text-3xl"> {{ headline }} </h2>
		<slot name="body"/>
	</div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
	name: "Container",
	props: {
		headline: {
			type: String,
			require: true
		}
	},
	mounted () {
		window.scrollTo(0,0);
	}
});
</script>

<style scoped>
</style>