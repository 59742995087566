import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue';
import Imprint from "@/views/Imprint.vue";
import Contact from "@/views/Contact.vue";
import DataProtection from "@/views/DataProtection.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/data-protection',
    name: 'dataProtection',
    component: DataProtection
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
