<template>
	<container class="container mb-10" :headline="headline">
		<template v-slot:body>
			<p class="mt-8 text-base leading-8" align="justify">
				Die BTN Technologies GmbH freut sich,
				dass Sie unsere Website besuchen. Datenschutz und Datensicherheit bei der
				Nutzung unserer Website sind für uns sehr wichtig. Wir möchten Sie daher an
				dieser Stelle darüber informieren, welche Ihrer personenbezogenen Daten wir
				bei Ihrem Besuch auf unserer Website erfassen und für welche Zwecke diese genutzt werden.<br/><br/>
				Da Gesetzesänderungen oder Änderungen unserer unternehmensinternen Prozesse eine Anpassung dieser
				Datenschutzerklärung erforderlich machen können, bitten wir Sie, diese Datenschutzerklärung regelmäßig durchzulesen.
				Die Datenschutzerklärung kann jederzeit unter Datenschutzerklärung abgerufen, abgespeichert und ausgedruckt werden.
			</p>

			<h2 class="mt-8 font-semibold">
				§ 1 Verantwortlicher und Geltungsbereich
			</h2>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Der Verantwortliche im Sinne der EU-Datenschutzgrundverordnung (im Folgenden: DSGVO) und anderer nationaler Datenschutzgesetze
				der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist: <br/><br/>

				BTN Technologies GmbH <br/>
				Rosenthaler Str. 72a <br/>
				10119 Berlin <br/>
				Deutschland<br/>
				E-Mail: privacy@btntechnologies.com <br/>
				Website: https://www.btntechnologies.com/ <br/><br/>

				Diese Datenschutzerklärung gilt für das Internetangebot der BTN Technologies GmbH,
				welches unter der Domain https://www.btntechnologies.com abrufbar ist.
			</p>
			<h2 class="mt-8 font-semibold">
				§ 2 Datenschutzbeauftragter
			</h2>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Der Datenschutzbeauftragte des Verantwortlichen ist: <br/><br/>






			</p>

			<h2 class="mt-8 font-semibold">
				§ 3 Grundsätze der Datenverarbeitung
			</h2>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
				Hierzu gehören beispielsweise Informationen wie Ihr Name, Ihr Alter, Ihre Anschrift, Ihre Telefonnummer, Ihr Geburtsdatum, Ihre E-Mail-Adresse,
				Ihre IP-Adresse oder das Nutzerverhalten. <br/><br/>
				Informationen, bei denen wir keinen (oder nur mit einem unverhältnismäßigen Aufwand einen) Bezug zu Ihrer Person herstellen können, z.B. durch Anonymisierung
				der Informationen, sind keine personenbezogenen Daten. Die Verarbeitung von personenbezogenen Daten (bspw. das Erheben, das Abfragen, die Verwendung, die Speicherung
				oder die Übermittlung) bedarf immer einer gesetzlichen Grundlage oder Ihrer Einwilligung. Verarbeitete personenbezogene Daten werden gelöscht, sobald der Zweck der
				Verarbeitung erreicht wurde und keine gesetzlich vorgeschriebenen Aufbewahrungspflichten mehr zu wahren sind. <br/><br/>
				Sofern wir für die Bereitstellung bestimmter Angebote Ihre personenbezogenen Daten verarbeiten, informieren wir Sie nachfolgend über die konkreten Vorgänge, den Umfang
				und den Zweck der Datenverarbeitung, die Rechtsgrundlage für die Verarbeitung und die jeweilige Speicherdauer.
			</p>

			<h2 class="mt-8 font-semibold">
				§ 4 Einzelne Verarbeitungsvorgänge
			</h2> <br/>

			<strong class="mt-2 leading-8" align="JUSTIFY">
				Bereitstellung und Nutzung der Webseite -Art und Umfang der Datenverarbeitung
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Bei Aufruf und Nutzung unserer Webseite erheben wir die personenbezogenen Daten, die Ihr Browser automatisch an unseren Server übermittelt. Diese Informationen werden
				temporär in einem sog. Logfile gespeichert. Wenn Sie unsere Webseite nutzen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere
				Webseite anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:
			</p>

			<ul class="py-8 px-6 list-disc text-base leading-8">
				<li>IP-Adresse des anfragenden Rechners</li>
				<li>Datum und Uhrzeit des Zugriffs</li>
				<li>Name und URL der abgerufenen Datei</li>
				<li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
				<li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
			</ul>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Rechtsgrundlage Für die genannte Datenverarbeitung dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage. Die Verarbeitung der genannten Daten ist für die Bereitstellung
				einer Webseite erforderlich und dient damit der Wahrung eines berechtigten Interesses unseres Unternehmens. Speicherdauer Sobald die genannten Daten zur Anzeige der Webseite
				nicht mehr erforderlich sind, werden sie gelöscht. Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung der Daten in Logfiles ist für den Betrieb der
				Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit. Eine weitergehende Speicherung kann im Einzelfall dann erfolgen,
				wenn dies gesetzlich vorgeschrieben ist.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Nutzerkonto - Art und Umfang der Datenverarbeitung
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Nach Vertragsschluss bieten wir Ihnen auf unserer Internetseite die Möglichkeit, sich unter Angabe personenbezogener Daten anzumelden (Log-In). <br/>
				Ihre Registrierung ist dabei zur Erfüllung eines Vertrags mit uns bzw. zur Durchführung vorvertraglicher Maßnahmen aus folgenden Gründen erforderlich.<br/>
				Mit den verarbeiteten Daten erstellen wir für Sie ein individualisiertes Nutzerkonto, mit dem Sie die Inhalte und Leistungen auf unserer Webseite nutzen können.
				Ihre E-Mail – Adresse verarbeiten wir, damit wir Ihnen neue Zugangsdaten zusenden können, falls Sie diese einmal vergessen sollten. <br/>
				Folgender Übersicht können Sie detailliert entnehmen, welche personenbezogenen Daten wir von Ihnen bei der Erstellung eines Nutzerkontos verarbeiten: <br/>
			</p>

			<ul class="py-8 px-6 list-disc text-base leading-8">
				<li>Name</li>
				<li>E-Mail – Adresse</li>
				<li>Anschrift</li>
				<li>Geburtsdatum</li>
				<li>IP-Adresse </li>
			</ul>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Rechtsgrundlage - Allgemeines Angebot (Einwilligung)
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Die Verarbeitung der dargelegten personenbezogenen Daten beruht nach Art. 6 Abs. 1 lit. a DSGVO auf der von Ihnen im Folgenden freiwillig abgegebenen Einwilligungserklärung:
				Einwilligungserklärung Ich willige ein, dass bei Anmeldung für das Nutzerkonto die genannten Daten gespeichert werden. Auf diese Weise kann ich mich bei künftigen Besuchen mit
				Benutzername und Passwort anmelden, ohne nochmals meine Daten eingeben zu müssen. Ich habe die aktuelle Datenschutzerklärung und AGB gelesen und akzeptiere sie. Diese Einwilligung
				kann ich jederzeit mit Wirkung für die Zukunft widerrufen, indem ich mein Benutzerkonto im Mitgliederbereich unter Kontoeinstellungen, dort „Konto löschen“ lösche oder mich an
				<a href = "mailto: privacy@btntechnologies.com">privacy@btntechnologies.com</a> wende.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Rechtsgrundlage - Vertrag
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 lit. b DSGVO der Erfüllung eines Vertrags zwischen Ihnen und BTN Technologies GmbH oder der Durchführung
				vorvertraglicher Maßnahmen.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Speicherdauer - Allgemeines Angebot
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Sobald die Registrierung auf unserer Internetseite aufgehoben oder abgeändert wird, werden die während des Registrierungsvorgangs verarbeiteten Daten gelöscht. Eine weitergehende
				Speicherung kann im Einzelfall dann erfolgen, wenn dies gesetzlich vorgeschrieben ist.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Speicherdauer - Vertrag
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Sobald die verarbeiteten Daten für die Durchführung des Vertrages nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Abschluss des Vertrages kann es erforderlich sein,
				personenbezogene Daten von Ihnen zu speichern, um vertraglichen oder gesetzlichen Verpflichtungen nachzukommen. Eine weitergehende Speicherung kann im Einzelfall dann erfolgen,
				wenn dies gesetzlich vorgeschrieben ist. Auflösung der Registrierung Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie gespeicherten
				Daten können Sie jederzeit abändern lassen.<br/><br/>
				Dazu gehen Sie folgendermaßen vor: Bitte teilen Sie hierzu Ihren Änderungswunsch unter Angabe Ihres
				Nutzerkontos an <a href="mailto: privacy@btntechnologies.com">privacy@btntechnologies.com</a> mit. <br/><br/>
				Falls die verarbeiteten Daten jedoch zur Erfüllung eines Vertrages oder zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, ist eine vorzeitige Löschung der Daten nur möglich,
				soweit dem nicht vertragliche oder gesetzliche Verpflichtungen entgegenstehen. Kontaktformular Art und Umfang der Datenverarbeitung Auf unserer Webseite bieten wir Ihnen an, über ein
				bereitgestelltes Formular mit uns in Kontakt zu treten, insbesondere für Preisanfragen und Demo-Vorführungen. Im Rahmen des Absendevorgangs Ihrer Anfrage über das Kontaktformular wird
				zur Einholung Ihrer Einwilligung auf diese Datenschutzerklärung verwiesen. Wenn Sie von dem Kontaktformular Gebrauch machen, werden darüber die folgenden personenbezogenen Daten von Ihnen verarbeitet: <br/>
			</p>

			<ul class="py-8 px-6 list-disc text-base leading-8">
				<li>Firma</li>
				<li>Name</li>
				<li>Telefonnummer</li>
				<li>Geburtsdatum</li>
				<li>E-Mail – Adresse</li>
			</ul>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Die Angabe Ihrer E-Mail – Adresse dient dabei dem Zweck, Ihre Anfrage zuzuordnen und Ihnen antworten zu können. Bei der Nutzung des Kontaktformulars erfolgt keine Weitergabe Ihrer personenbezogenen Daten an Dritte.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Rechtsgrundlage
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Die vorgehend beschriebene Datenverarbeitung zum Zwecke der Kontaktaufnahme erfolgt nach Art. 6 Abs. 1 lit. a DSGVO auf der von Ihnen freiwillig abgegebenen Einwilligungserklärung.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Speicherdauer
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Sobald die von Ihnen gestellte Anfrage erledigt und der betreffende Sachverhalt abschließend geklärt ist, werden die über das Kontaktformular verarbeiteten personenbezogenen Daten von Ihnen gelöscht.
				Eine weitergehende Speicherung kann im Einzelfall erfolgen, wenn dies gesetzlich vorgeschrieben ist.
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 5 Weitergabe von Daten
			</h2> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn: Sie hierzu Ihre ausdrückliche Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben,
				dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Erfüllung eines Vertragsverhältnisses mit Ihnen erforderlich ist,
				nach Art. 6 Abs. 1 S. 1 lit. c DSGVO für die Weitergabe eine gesetzliche Verpflichtung besteht oder die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
				Wahrung berechtigter Unternehmensinteressen, sowie zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht,
				dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 6 Einsatz von Cookies
			</h2> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Art und Umfang der Datenverarbeitung
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Wir setzen auf unserer Webseite Cookies ein. Cookies sind kleine Dateien, die im Rahmen Ihres Besuchs unserer Internetseiten von uns an den Browser
				Ihres Endgeräts gesendet und dort gespeichert werden. Einige Funktionen unserer Internetseite können ohne den Einsatz technisch notwendiger Cookies nicht angeboten werden.
				Andere Cookies ermöglichen uns hingegen verschiedene Analysen. Cookies sind beispielsweise in der Lage, den von Ihnen verwendeten Browser bei einem erneuten Besuch unserer Webseite
				wiederzuerkennen und verschiedene Informationen an uns zu übermitteln. Mithilfe von Cookies können wir unter anderem unser Internetangebot für Sie nutzerfreundlicher und effektiver gestalten,
				indem wir etwa Ihre Nutzung unserer Webseite nachvollziehen und Ihre bevorzugten Einstellungen (bspw. Länder- und Spracheneinstellungen) feststellen. Sofern Dritte über Cookies Informationen verarbeiten,
				erheben diese die Informationen direkt über Ihren Browser. Cookies richten auf Ihrem Endgerät keinen Schaden an. Sie können keine Programme ausführen und keine Viren enthalten. Auf unserer Webseite werden
				verschiedene Arten von Cookies verwendet, deren Art und Funktion nachfolgend näher erläutert wird.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Transient-Cookies
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Auf unserer Webseite werden Transient-Cookies verwendet, die automatisch gelöscht werden, sobald Sie Ihren Browser schließen. Diese Art von Cookies ermöglicht es, Ihre Session-ID zu erfassen.
				Dadurch lassen sich verschiedene Anfragen Ihres Browsers einer gemeinsamen Sitzung zuordnen und es ist uns möglich, Ihr Endgerät bei späteren Webseitenbesuchen innerhalb einer Session wiederzuerkennen.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Persistent-Cookies
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Auf unserer Webseite werden Persistente-Cookies eingesetzt. Persistent-Cookies sind Cookies, die über einen längeren Zeitraum in Ihrem Browser gespeichert werden und Informationen an uns übermitteln.
				Die jeweilige Speicherdauer unterscheidet sich je nach Cookie. Sie können Persistent-Cookies eigenständig über Ihre Browsereinstellungen löschen.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Erforderliche Cookies
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Diese Cookies werden aus technischen Gründen benötigt, damit Sie unsere Webseite besuchen und von uns angebotene Funktionen nutzen können. Außerdem tragen diese Cookies zu einer sicheren und vorschriftsgemäßen Nutzung der Webseite bei.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Leistungsbezogene Cookies
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Mit Hilfe dieser Cookies ist es uns möglich, eine Analyse der Webseitennutzung durchzuführen und die Leistung und Funktionalität unserer Webseite zu verbessern. Es werden etwa Informationen darüber erfasst,
				wie unsere Webseite von Besuchern genutzt wird, welche Seiten am häufigsten aufgerufen werden, oder ob auf bestimmten Seiten Fehlermeldungen angezeigt werden.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Speicherdauer
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Sobald die über die Cookies an uns übermittelten Daten für die Erreichung der oben beschriebenen Zwecke nicht mehr erforderlich sind, werden diese Informationen gelöscht, insbesondere bei Deaktivierung der Cookies.
				Eine weitergehende Speicherung kann im Einzelfall dann erfolgen, wenn dies gesetzlich vorgeschrieben ist. Eine genaue Auflistung der Speicherdauer pro eingesetztem Cookie entnehmen Sie bitte unserem Cookie-Banner.
				Den erreichen Sie über <a href="https://btntechnologies.com/">https://btntechnologies.com/</a> indem Sie den
				Schild-Button	am rechten unteren Rand klicken.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Konfiguration der Browsereinstellungen
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Die Verwaltung der Cookie-Einstellungen ist für Sie über den Cookie Banner sowie durch die Konfiguration Ihrer Browsereinstellungen möglich. Die meisten Browser sind so voreingestellt, dass sie Cookies standardmäßig akzeptieren.
				Sie können Ihren jeweiligen Browser jedoch so konfigurieren, dass er nur noch bestimmte oder auch gar keine Cookies mehr akzeptiert. Wir weisen Sie jedoch darauf hin, dass Sie möglicherweise nicht mehr alle Funktionen unserer Webseite nutzen können,
				wenn Cookies durch Ihre Browsereinstellungen auf unserer Webseite deaktiviert werden. Über Ihre Browsereinstellungen können Sie auch bereits in Ihrem Browser gespeicherte Cookies löschen oder sich die Speicherdauer anzeigen lassen. Weiterhin ist es möglich,
				Ihren Browser so einzustellen, dass er Sie benachrichtigt, bevor Cookies gespeichert werden. Da sich die verschiedenen Browser in ihren jeweiligen Funktionsweisen unterscheiden können, bitten wir Sie, das jeweilige Hilfe-Menü Ihres Browsers für die Konfigurationsmöglichkeiten in Anspruch zu nehmen.
				Falls Sie eine umfassende Übersicht aller Zugriffe Dritter auf Ihren Internetbrowser wünschen, empfehlen wir Ihnen die Installation hierzu speziell entwickelter Plug-Ins.
			</p>

			<h2 class="mt-8 font-semibold">
				§ 7 Tracking- und Analysetools
			</h2> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Wir nutzen Tracking- und Analysetools um eine fortlaufende Optimierung und bedarfsgerechte Gestaltung unserer Webseite sicherzustellen.
				Mit Hilfe von Tracking-Maßnahmen ist es uns auch möglich, die Nutzung unserer Webseite durch Besucher statistisch zu erfassen und unser
				Onlineangebot mit Hilfe der dadurch gewonnenen Erkenntnisse für Sie weiterzuentwickeln. Falls Sie uns auf Grundlage eines von uns auf der
				Webseite erteilten Hinweises („Cookie-Banner“) Ihre Einwilligung zur Verwendung von Cookies erteilt haben, richtet sich die Rechtmäßigkeit der
				Verwendung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO. Der nachfolgenden Beschreibung der Tracking- und Analysetools lassen sich auch die
				jeweiligen Verarbeitungszwecke und die verarbeiteten Daten entnehmen.
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Google Analytics
			</strong>
			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Auf dieser Webseite wird Google Analytics, ein Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountainview, CA 94043 USA („Google“),
				genutzt. Google Analytics verwendet sogenannte „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
				der Webseite durch Sie ermöglichen. Die durch diese Cookies erzeugten Informationen, beispielsweise über Zeit, Ort und Häufigkeit Ihrer Benutzung
				dieser Webseite, werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Bei der Verwendung von Google Analytics
				ist es nicht ausgeschlossen, dass die von Google Analytics gesetzten Cookies neben der IP-Adresse auch weitere personenbezogene Daten erfassen können.
				Wir weisen Sie darauf hin, dass Google diese Informationen gegebenenfalls an Dritte übertragen wird, sofern dies gesetzlich vorgeschrieben ist, oder
				soweit Dritte diese Daten im Auftrag von Google verarbeiten. Die durch Cookies erzeugten Informationen wird Google im Auftrag des Betreibers
				dieser Webseite benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und weitere mit der
				Webseitenbenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von
				Google Analytics von Ihrem Browser übermittelte IP-Adresse wird von Google nach eigenen Angaben nicht mit anderen Daten von Google zusammengeführt.
				Sie können eine Speicherung von Cookies generell durch eine entsprechende Einstellung Ihrer Browser-Software verhindern.
				Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen können.
				Es ist nicht ausgeschlossen, dass die von Google Analytics gesetzten Cookies neben der IP-Adresse weitere personenbezogene Daten erfassen können.
				Um zu verhindern, dass Informationen zu Ihrer Nutzung der Webseite durch Google Analytics erfasst und an Google Analytics
				übertragen werden, können Sie unter folgendem Link ein Plugin für Ihren Browser herunterladen und
				installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
				Dieses Plugin verhindert, dass Informationen zu Ihrem Besuch der Webseite an Google Analytics übermittelt werden.
				Eine anderweitige Analyse wird durch dieses Plugin nicht unterbunden.
				Wir weisen Sie darauf hin, dass Sie das oben beschriebene Browser-Plugin bei einem Besuch unserer Webseite über den Browser eines mobilen Endgeräts (Smartphone oder Tablet) nicht benutzen können.
				Durch das Anklicken dieses Links wird ein sogenanntes Opt-out Cookie in Ihren Browser gesetzt. Dieses verhindert, dass Informationen zu Ihrem Besuch der Webseite an Google Analytics übermittelt werden.
				Bitte beachten Sie, dass das Opt-out Cookie nur für diesen Browser und nur für diese Domain gültig ist. Wenn Sie die Cookies in diesem Browser löschen, wird auch das Opt-out Cookie gelöscht.
				Um die Erfassung durch Google Analytics weiterhin zu verhindern, müssen Sie den Link erneut anklicken. Die Nutzung des Opt-out Cookies ist auch als Alternative zu obigen Plugin bei der Nutzung des Browsers auf Ihrem Computer möglich.
				Um den bestmöglichen Schutz Ihrer personenbezogenen Daten zu gewährleisten, wurde Google Analytics auf dieser Webseite um den Code „anonymizeIp“ erweitert. Dieser Code bewirkt,
				dass die letzten 8 Bit der IP-Adressen gelöscht werden und Ihre IP-Adresse somit anonymisiert erfasst wird (sog. IP-Masking).
				Ihre IP-Adresse wird dabei von Google grundsätzlich schon vor der Übertragung innerhalb von Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und dadurch anonymisiert.
				Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Rahmen der Nutzung von Google Analytics können personenbezogene Daten in Staaten außerhalb der EU/des EWR übermittelt werden, insbesondere in die USA.
				Ihre im Rahmen des Cookie-Banners abgegebene Einwilligung in den Einsatz von Google Analytics erstreckt sich auch auf die Übermittlung Ihres Nutzerverhaltens an Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043).
				Google behält sich bei den erhaltenen Daten vor, diese auch für andere Zwecke zu nutzen und Ihre Daten mit anderen zu verknüpfen.
				Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach EU-Standards unzureichenden Datenschutzniveau eingeschätzt.
				Aufgrund des Fehlens eines Angemessenheitsbeschlusses und ohne geeignete Garantien besteht insbesondere das Risiko,
				dass Ihre Daten durch US-Behörden zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können.
				Sie haben jederzeit die Möglichkeit, diese Einwilligung zu widerrufen. Nähere Informationen hierzu finden Sie unter <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a> bzw. unter <a href="https://policies.google.com/">https://policies.google.com/</a>
				(allgemeine Informationen zu Google Analytics und Datenschutz).
			</p> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Google Remarketing
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Wir nutzen die Technologie „Google Remarketing“ der Firma Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland.
				Mit Google Remarketing werden Anzeigen für Nutzer geschaltet, die unsere Webseiten und Onlinedienste bereits besucht und sich für ein bestimmtes Angebot interessiert haben.
				Innerhalb des Google-Werbenetzwerks können hierdurch zielgerichtete und interessenbasierte Werbeanzeigen auf unserer Seite geschaltet werden.
				Google-Remarketing verwendet für diese Analyse Cookies. Hierdurch können unsere Besucher wiedererkannt werden, sobald sie Webseiten innerhalb des Werbenetzwerks von Google aufrufen.
				Innerhalb des Werbenetzwerks von Google können so zielgerichtet und interessenbasiert Werbeanzeigen geschaltet werden,
				die auf den vom Besucher zuvor besuchten Webseiten des Werbenetzwerks von Google, (die ebenfalls die Remarketing Funktion von Google verwenden) basieren.
				Im Rahmen der Nutzung von Google Remarketing können personenbezogene Daten in Staaten außerhalb der EU/des EWR übermittelt werden, insbesondere in die USA.
				Ihre im Rahmen des Cookie-Banners abgegebene Einwilligung in den Einsatz von Google Remarketing erstreckt sich auch auf die Übermittlung Ihres Nutzerverhaltens an Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043).
				Google behält sich bei den erhaltenen Daten vor, diese auch für andere Zwecke zu nutzen und Ihre Daten mit anderen zu verknüpfen.
				Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach EU-Standards unzureichenden Datenschutzniveau eingeschätzt.
				Aufgrund des Fehlens eines Angemessenheitsbeschlusses und ohne geeignete Garantien besteht insbesondere das Risiko,
				dass Ihre Daten durch US-Behörden zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können.
				Sie haben jederzeit die Möglichkeit, diese Einwilligung zu widerrufen. Wenn Sie keine zielgerichtete, interessenbasierte Werbung angezeigt bekommen möchten,
				können Sie die Verwendung von Cookies durch Google für diese Zwecke über den Link: https://www.google.de/settings/ads deaktivieren.
				Nähere Informationen hierzu finden Sie unter <a href="https://support.google.com/ads/answer/2662922?hl=de">https://support.google.com/ads/answer/2662922?hl=de</a> bzw.
				unter <a href="https://policies.google.com/">https://policies.google.com/</a> (allgemeine Informationen zu Google Remarketing und Datenschutz).
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 8 Plugins
			</h2> <br/>

			<strong class="mt-2 text-base leading-8" align="JUSTIFY">
				Facebook, Google+
			</strong>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Auf unserer Website kommen Social-Media Plugins von Facebook und Google+ zum Einsatz.
				Es ist möglich, dass über diese Plugins auch personenbezogene Daten über die Besucher der Webseite erhoben, an den jeweiligen Dienst übermittelt und mit dem jeweiligen Dienst des Besuchers verknüpft werden.
				BTN Technologies GmbH selbst erfasst über die Social Plugins und über deren Nutzung keine personenbezogenen Daten.
				Um zu verhindern, dass Daten ohne das Wissen des Nutzers an die Dienstanbieter in den USA übertragen werden, haben wir auf unserer Webseite passive Social Media Buttons oder die sogenannte Shariff-Lösung eingesetzt.
				Diese bewirkt, dass die jeweiligen Social Plugins auf der Webseite zunächst nur als Grafik eingebunden werden. Diese Grafiken enthalten eine Verlinkung auf die Webseite des entsprechenden Anbieters des Plugins.
				Erst wenn Sie eine der Grafiken anklicken, werden Sie zum Dienst des jeweiligen Anbieters weitergeleitet. Durch diese Lösung wird sichergestellt, dass nicht automatisch personenbezogene Daten an die Anbieter der jeweiligen
				Social Plugins weitergeleitet werden, wenn Sie unsere Webseite besuchen. Wenn Sie eine der Grafiken der Social Plugins anklicken, können Daten an den jeweiligen Dienstanbieter übertragen und dort gespeichert werden.
				Sofern Sie keine der Grafiken anklicken, findet auch keine Datenübertragung zwischen Ihnen und dem jeweiligen Anbieter des Social Plugins statt.
				Nach dem Anklicken eines Social Plugins erhält der jeweilige Dienstanbieter die Information, dass Sie die entsprechende Seite unseres Online-Angebots besucht haben.
				Bitte beachten Sie, dass Sie hierfür weder ein Benutzerkonto bei dem betreffenden Dienst haben müssen, noch dort bereits eingeloggt sind.
				Sollten Sie allerdings bei dem betreffenden Dienstanbieter bereits ein Benutzerkonto haben und während des Besuchs unserer Webseite in diesem Konto auch schon eingeloggt sein,
				werden die von dem Social Plugin erhobenen Daten direkt Ihrem Konto zugeordnet. Falls Sie die Zuordnung zu Ihrem Profil bei dem Dienstanbieter nicht wünschen,
				müssen Sie sich vor dem Klick auf eines der Social Plugins aus Ihrem Benutzerkonto ausloggen. Beachten Sie, dass BTN Technologies GmbH keinen Einfluss darauf hat, ob und in welchem Umfang die jeweiligen
				Dienstanbieter personenbezogene Daten erheben. Umfang, Zweck und Speicherfristen der jeweiligen Datenerhebung sind uns nicht bekannt. Wir weisen Sie allerdings darauf hin, dass davon ausgegangen werden muss,
				dass über Social Plugins zumindest die IP-Adresse und gerätebezogene Informationen genutzt und erfasst werden. Es ist außerdem möglich, dass die jeweiligen Dienstanbieter Cookies einsetzen.
				Im Rahmen der Nutzung der Plugins können personenbezogene Daten in Staaten außerhalb der EU/des EWR übermittelt werden, insbesondere in die USA.
				Die Anbieter behalten sich bei den erhaltenen Daten vor, diese auch für andere Zwecke zu nutzen und Ihre Daten mit anderen zu verknüpfen.
				Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach EU-Standards unzureichenden Datenschutzniveau eingeschätzt.
				Aufgrund des Fehlens eines Angemessenheitsbeschlusses und ohne geeignete Garantien besteht insbesondere das Risiko, dass Ihre Daten durch US-Behörden zu Kontroll- und zu Überwachungszwecken,
				möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können. Sie haben jederzeit die Möglichkeit, diese Einwilligung zu widerrufen.
				Umfang und Zweck der Datenerhebung durch den jeweiligen Dienst sowie die dortige weitere Verarbeitung und Nutzung Ihrer Daten entnehmen Sie bitte den Datenschutzhinweisen direkt von der Webseite des jeweiligen Dienstes.
				Dort erhalten Sie auch weitere Informationen über Ihre entsprechenden Datenschutzrechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre. <br/>
				a) Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA https://www.facebook.com/policy.php https://www.facebook.com/help/186325668085084 <br/>
				b) Google Inc., 1600 Amphitheater Parkway, Mountain View, California 94043, USA,  <a href="https://www.google.com/policies/privacy/partners/?hl=de">https://www.google.com/policies/privacy/partners/?hl=de</a>
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 9 Hyperlinks
			</h2><br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Auf unserer Webseite befinden sich sog. Hyperlinks zu Webseiten anderer Anbieter. Bei Aktivierung dieser Hyperlinks werden Sie von unserer Webseite direkt auf die Webseite der anderen Anbieter weitergeleitet.
				Sie erkennen dies u. a. am Wechsel der URL. Wir können keine Verantwortung für den vertraulichen Umgang Ihrer Daten auf diesen Webseiten Dritter übernehmen,
				da wir keinen Einfluss darauf haben, dass diese Unternehmen die Datenschutzbestimmungen einhalten. Über den Umgang mit Ihren personenbezogenen Daten durch diese
				Unternehmen informieren Sie sich bitte direkt auf diesen Webseiten.
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 10 Betroffenenrechte
			</h2> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Aus der DSGVO ergeben sich für Sie als Betroffener einer Verarbeitung
				personenbezogener Daten die folgenden Rechte: Gemäß Art. 15 DSGVO können
				Sie Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen.
				Sie können insbesondere Auskunft über die Verarbeitungszwecke,
				die Kategorien der personenbezogenen Daten, die Kategorien von Empfängern,
				gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer,
				das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung
				der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
				die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, über eine Übermittlung
				in Drittländer oder an internationale Organisationen sowie über das Bestehen
				einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige
				Informationen zu deren Einzelheiten verlangen.
				Gemäß Art. 16 DSGVO können Sie unverzüglich die Berichtigung unrichtiger oder die
				Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten verlangen.
				Gemäß Art. 17 DSGVO können Sie die Löschung Ihrer bei uns
				gespeicherten personenbezogenen Daten verlangen, soweit die Verarbeitung nicht zur
				Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
				rechtlichen Verpflichtung, aus Gründen des öffentlichen
				Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von
				Rechtsansprüchen erforderlich ist. Gemäß Art. 18 DSGVO können Sie die Einschränkung
				der Verarbeitung Ihrer personenbezogenen Daten verlangen,
				soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist,
				wir die Daten nicht mehr benötigen und Sie deren Löschung ablehnen,
				weil Sie diese zur Geltendmachung, Ausübung oder
				Verteidigung von Rechtsansprüchen benötigen. Das Recht aus Art. 18 DSGVO steht Ihnen auch zu,
				wenn Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben.
				Gemäß Art. 20 DSGVO können Sie verlangen,
				Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
				gängigen und maschinenlesebaren Format zu erhalten oder Sie können die Übermittlung an
				einen anderen Verantwortlichen verlangen.
				Gemäß Art. 7 Abs. 3 DSGVO können Sie Ihre einmal erteilte Einwilligung jederzeit
				gegenüber uns widerrufen. Dies hat zur Folge, dass wir die auf dieser
				Einwilligung beruhende Datenverarbeitung für die Zukunft nicht mehr fortführen dürfen.
				Gemäß Art. 77 DSGVO steht Ihnen das Recht zu, sich bei einer Aufsichtsbehörde zu beschweren.
				In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes,
				Ihres Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 11 Widerspruchsrecht
			</h2> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Bei der Verarbeitung Ihrer personenbezogenen Daten auf Grundlage berechtigter
				Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO, haben Sie das Recht,
				gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
				Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen
				Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet.
				Im Fall der Direktwerbung besteht für Sie ein generelles Widerspruchsrecht,
				das von uns ohne Angabe einer besonderen Situation umgesetzt wird.
			</p> <br/>

			<h2 class="mt-8 font-semibold">
				§ 12 Datensicherheit und Sicherungsmaßnahmen
			</h2> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">
				Wir verpflichten uns, Ihre Privatsphäre zu schützen und Ihre personenbezogenen
				Daten vertraulich zu behandeln. Um eine Manipulation, einen Verlust oder einen
				Missbrauch Ihrer bei uns gespeicherten Daten zu vermeiden, treffen wir umfangreiche
				technische und organisatorische Sicherheitsvorkehrungen, die regelmäßig überprüft
				und dem technologischen Fortschritt angepasst werden. Hierzu gehört unter anderem
				die Verwendung anerkannter Verschlüsselungsverfahren (SSL oder TLS).
				Wir weisen Sie jedoch darauf hin, dass es aufgrund der Struktur des Internets
				möglich ist, dass die Regeln des Datenschutzes und die o. g. Sicherungsmaßnahmen von anderen,
				nicht innerhalb unseres Verantwortungsbereichs liegenden Personen
				oder Institutionen nicht beachtet werden. Insbesondere können unverschlüsselt
				preisgegebene Daten – z. B. wenn dies per E-Mail erfolgt – von Dritten mitgelesen werden.
				Wir haben technisch hierauf keinen Einfluss. Es liegt im Verantwortungsbereich des Nutzers,
				die von ihm zur Verfügung gestellten Daten durch Verschlüsselung oder in sonstiger Weise gegen Missbrauch zu schützen.
			</p> <br/>

			<p class="mt-2 text-base leading-8" align="JUSTIFY">Stand März 2023</p>
		</template>
	</container>
</template>

<script>
import { defineComponent } from "vue";
import Container from "@/components/Container.vue";
export default defineComponent({
	name: "DataProtection",
	components: {
		Container
	},
	data() {
		return {
			headline: "Datenschutzerklärung"
		}
	},
	mounted () {
		window.scrollTo(0,0);
	}
});
</script>

<style scoped>
.container {
	margin-top: 123px;
}
</style>