<template>
<div class="flex flex-col">
	<banner />
	<container class="container self-center sm:px-0 md:px-1/675 lg:px-1/6 xl:px-1/7" :headline="headline">
		<template  v-slot:body>
			<p class="leading-8 text-base mt-8 mb-16">
				BTN Technologies ist spezialisiert auf die Konsolidierung und Aggregation wichtiger
				Meta-Gesundheitsdaten. Durch die zentralisierte Struktur entstehen völlig neue Möglichkeiten
				für Politik und Forschung in den unterschiedlichsten Bereichen, wichtige Entscheidungen auf einer
				intelligenten Datenbasis zu treffen. Forschende erhalten wichtige übergreifende Meta-Daten um Rückschlüsse zu treffen,
				die vorher nur mit extremen Finanzmitteln möglich waren. Die Politik erhält eine Übersicht,
				um öffentliche Mittel zielgerichtet in Maßnahmen zu investieren und wichtige Entscheidungen
				auf einer genauen Datenbasis zu treffen.  BTN Technologies spart durch die Zentralisierung Zeit
				und Ressourcen, ohne dabei auf eine hohe Datenqualität zu verzichten. Wir verstehen uns als
				Beschleuniger der Evolution im Gesundheitswesen.
			</p>
			<div class="flex flex-col">
				<extern-link-wrapper
					:link="linkBackToNormality"
					:icon="iconGermany"
					:title="titleBackToNormality"
					:sub-title="subTitleBackToNormality"
				/>
				<extern-link-wrapper
					:link="linkAudionom"
					:icon="iconAudionom"
					:title="titleAudionom"
					:sub-title="subTitleAudionom"
				/>
			</div>
		</template>
	</container>
</div>
</template>

<script>
import { defineComponent } from "vue";
import Banner from "@/components/Banner.vue";
import Container from "@/components/Container.vue";
import ExternLinkWrapper from "@/components/shared/ExternLinkWrapper.vue";
export default defineComponent({
	name: "Home",
	components: {
		Banner,
		Container,
		ExternLinkWrapper
	},
	data() {
		return {
			linkBackToNormality: "https://www.backtonormality.org/",
			titleBackToNormality: "backtonormality.org",
			subTitleBackToNormality: "Pandemie-Infektionsgeschehen in Echtzeit",
			iconGermany: "/icons/germany-icon.svg",
			linkAudionom: "https://www.audionom.com/",
			titleAudionom: "audionom – Hörstudie",
			subTitleAudionom: "Untersuchung im Zusammenhang einer COVID-19 Infektion",
			iconAudionom: "/icons/audionom-circle.svg",
			headline: "Bessere Daten für eine bessere Zukunft."
		}
	}
});
</script>

<style scoped>
</style>