<template>
	<div class="h-full flex flex-col justify-between">
		<header-view/>
		<router-view />
		<footer-view />
	</div>
</template>
<script>
import HeaderView from "@/components/shared/HeaderView.vue";
import FooterView from "@/components/shared/FooterView.vue";
export default {
	components: {
		HeaderView,
		FooterView
	},
	mounted () {
		window.scrollTo(0,0);
	}
}
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	padding-top: 28px;
}

</style>
